import React, {useState} from 'react';
import Layout from "../components/Layout";
import SideBar from "../components/SideBar";
import BasicForm from "../components/BasicForm";
import ImageForm from "../components/ImageForm";
import SEO from "../components/SEO";

const CreateDia = ({history}) => {

    const [name, setName] = useState("basic");

    const handleSidebarNameChange = (activeName) => {
        setName(activeName);
    }

    return (
        <Layout>
            <SEO title="Create Slides" description="Create a new slide for your presentation"/>
            <div className="flex-grow bg-gray-900 flex items-center relative">
                <SideBar
                    onNameChange={handleSidebarNameChange}/>

                {
                    name === "basic" &&
                    <>
                        <BasicForm
                            history={history}/>
                    </>
                }
                {
                    name === "image" &&
                    <>
                        <ImageForm
                            history={history}/>
                    </>
                }
                {/*{*/}
                {/*    name === "twoColumns" &&*/}
                {/*    <>*/}
                {/*        <TwoColumnForm*/}
                {/*            history={history}/>*/}
                {/*    </>*/}
                {/*}*/}

            </div>
        </Layout>
    );
};

export default CreateDia;
