import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Dia from "../components/Dia";
import {Slide} from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import {BsFillBackspaceFill} from "react-icons/bs";
import {UserContext} from "../context/UserContext";
import {getUserToken, isVideo} from "../utils/helpers";
import LoadingGif from "../components/LoadingGif";
import SEO from "../components/SEO";

const DiaPresentation = ({history}) => {

    const {user} = useContext(UserContext);

    const slideRef = useRef();

    const [isAdmin, setIsAdmin] = useState(false);
    const [dias, setDias] = useState([]);
    const [playing, setPlaying] = useState(null);
    const [adminProperties, setAdminProperties] = useState({
        infinite: true,
        transitionDuration: 500,
        arrows: true,
        autoplay: false,
        pauseOnHover: false,
    });
    const [loading, setLoading] = useState(true);

    const slideProperties = {
        duration: 0,
        transitionDuration: 500,
        infinite: true,
        arrows: false,
        autoplay: false,
        pauseOnHover: false,
        onChange: (previous, next) => {
            setDuration(dias[next].duration);

            if (isVideo(dias[previous].image.mime)) {
                setPlaying(false);
            }
            if (isVideo(dias[next].image.mime)) {
                setPlaying(true);
            }
        }
    }

    const setDuration = (duration) => {
        const durationTimer = setTimeout(() => {
            if (slideRef.current) {
                slideRef.current.goNext()
            }
        }, duration || 5000);
        return () => clearTimeout(durationTimer);
    }


    const getPresentation = async () => {
        try {
            const dataResponse = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/presentations/user`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
            const data = await dataResponse.data;
            setDias(data[0].dias);
            localStorage.setItem("dias", JSON.stringify(data[0].dias));
            setDuration(data[0].dias[0].duration)
            isVideo(data[0].dias[0].image.mime) ? setPlaying(true) : setPlaying(false);
            setLoading(false);
        } catch (e) {
            history.push("/dias/presentation");
        }
    }

    useEffect(() => {
        if (user.value.role.type === "admin") {
            setDias(JSON.parse(localStorage.getItem("dias")));
            setAdminProperties({...adminProperties});
            setIsAdmin(true);
            setLoading(false);
        } else {
            getPresentation();
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div>
            <SEO title="Presentation Preview" description="A preview of your created presentation"/>
            <div
                className="top-0 left-0 absolute text-4xl ml-6 hover:text-gray-700 hover:scale-110 transform duration-500 z-10">
                <button onClick={() => history.push("/dias/presentation")}>
                    <BsFillBackspaceFill/>
                </button>
            </div>
            {
                loading &&
                <LoadingGif/>
            }
            {
                !loading && !isAdmin &&
                <Slide ref={slideRef} {...slideProperties}>
                    {
                        dias.map((dia) => {
                            return (
                                <div key={dia.id} className="relative">
                                    <Dia
                                        title={dia.title}
                                        image={dia.image}
                                        description={dia.description}
                                        logo={dia.logo ? dia.logo.url : null}
                                        playing={playing}/>
                                </div>
                            )
                        })
                    }
                </Slide>
            }
            {
                !loading && isAdmin &&
                <Slide {...adminProperties}>
                    {
                        dias.map((dia) => {
                            return (
                                <div key={dia.id} className="relative">
                                    <Dia
                                        title={dia.title}
                                        image={dia.image}
                                        description={dia.description}
                                        logo={dia.logo ? dia.logo.url : null}/>
                                </div>
                            )
                        })
                    }
                </Slide>
            }
        </div>
    );
};

export default DiaPresentation;
