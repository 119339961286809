import React from 'react';
import {getImgURL, isVideo} from "../utils/helpers";

const SlideCard = ({image, description, title, active}) => {
    return (
        <div className="relative">
            <div className="text-4xl text-center">
                {title}
                {
                    active &&
                    <div className="bg-teal-100 border-t-4 border-teal-500
                                                text-lg text-green-600 px-2 py-1 shadow-md w-1/4" role="alert">
                        <div className="flex justify-center">
                            <div>
                                <p className="font-semibold">Active</p>
                            </div>
                        </div>
                    </div>
                }
                {
                    !active &&
                    <div
                        className="bg-red-100 border-t-4 border-red-500
                                                text-lg text-red-600 px-2 py-1 shadow-md w-1/4"
                        role="alert">
                        <div className="flex justify-center">
                            <div>
                                <p className="font-semibold">Inactive</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                image && !isVideo(image.mime) &&
                <div className="h-56 overflow-hidden">
                    <img src={getImgURL(image.url)} className="bg-center"
                         alt="eye-catcher"/>
                </div>
            }
            {
                image && isVideo(image.mime) &&
                <video className="bg-gray-300 h-64 w-full rounded-lg shadow-md bg-cover object-fill">
                    <source src={getImgURL(image.url)}/>
                </video>
            }
            <div className="w-32 md:w-40 bg-white shadow-lg rounded-lg absolute inner-text-slide">
                <div className="flex justify-center items-center py-2 text-center font-bold
                uppercase tracking-wide text-gray-800">
                    <span className="">{image.ext}</span>
                </div>
            </div>
            <div className="text-center">
                <p>{description}</p>
            </div>
        </div>
    );
};

export default SlideCard;
