import React from 'react';
import logoWestIT from "../img/west-it-logo.png"

const Footer = () => {
    return (
        <footer className="flex text-white items-center justify-between flex-wrap bg-gray-200 p-2">
            <div>
                <p className="text-black font-thin text-xs">Vlinderweg 2, 2623 AX Delft</p>
            </div>
            <div>
                <ul className="flex">
                    <li>
                        <a href="https://www.west.nl/" target="_blank" rel="noreferrer noopener">
                           <img src={logoWestIT} style={{height: 35, width: 100}} alt="logo" />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
