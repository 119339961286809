import React, {useContext} from 'react';
import {UserContext} from "../context/UserContext";
import {NavLink} from "react-router-dom";
import {FaUserCircle} from "react-icons/fa"

const Nav = () => {

    const {user} = useContext(UserContext);

    const handleLogOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("jwt");

        window.location = "/login";
    }

    return (
        <div>
            <nav className="flex items-center justify-between flex-wrap bg-gray-200 p-6">
                <NavLink to="/" className="flex items-center flex-shrink-0 text-white mr-6">
                    <span className="font-semibold text-white text-xl tracking-tight border border-blue-500
                    rounded py-1 px-3 bg-blue-400 flex items-center"><FaUserCircle className="mr-4"/> Admin Panel</span>
                </NavLink>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <div className="text-sm lg:flex-grow">
                        <NavLink to="/dias" exact={true} activeClassName="border-b-4 border-blue-800 text-gray-800"
                                 className="block mt-4 lg:inline-block lg:mt-0 text-gray-600 mr-4 text-lg font-semibold
                                 hover:text-blue-900 transition duration-500">
                            Slides
                        </NavLink>
                        <NavLink to="/dias/presentation" exact={true} activeClassName="border-b-4 border-blue-800 text-gray-800"
                                 className="block mt-4 lg:inline-block lg:mt-0 text-gray-600 mr-4 text-lg font-semibold
                                 hover:text-blue-900 transition duration-500">
                            Presentation
                        </NavLink>
                        <NavLink to="/help" exact={true} activeClassName="border-b-4 border-blue-800 text-gray-800"
                                 className="block mt-4 lg:inline-block lg:mt-0 text-gray-600 mr-4 text-lg font-semibold
                                 hover:text-blue-900 transition duration-500">
                            Help
                        </NavLink>
                        <NavLink to="/archive" exact={true} activeClassName="border-b-4 border-blue-800 text-gray-800"
                                 className="block mt-4 lg:inline-block lg:mt-0 text-gray-600 mr-4 text-lg font-semibold
                                 hover:text-blue-900 transition duration-500">
                            Archive
                        </NavLink>
                        <NavLink to="/introduction" exact={true} activeClassName="border-b-4 border-blue-800 text-gray-800"
                                 className="block mt-4 lg:inline-block lg:mt-0 text-gray-600 mr-4 text-lg font-semibold
                                 hover:text-blue-900 transition duration-500">
                            Introduction
                        </NavLink>
                        {
                            user.value.role.name === "Admin" &&
                            <NavLink to="/admin" exact={true} activeClassName="border-b-4 border-blue-800 text-gray-800"
                                     className="block mt-4 lg:inline-block lg:mt-0 text-gray-600 mr-4 text-lg font-semibold
                                 hover:text-blue-900 transition duration-500">
                                Admin
                            </NavLink>
                        }
                    </div>

                    <p className="mr-5 font-semibold">Welcome back {user.value.username}!</p>
                    <div>
                        <button onClick={handleLogOut}
                                className="inline-block text-sm px-4 py-2 leading-none text-white
                           mt-4 lg:mt-0 transition duration-500 ease-in-out bg-blue-500 hover:bg-red-500 transform
                           hover:scale-110">
                            Log out
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Nav;
