import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserContextProvider from "./context/UserContext";
import {HelmetProvider} from "react-helmet-async";

ReactDOM.render(
    <React.StrictMode>
        <UserContextProvider>
            <HelmetProvider>
                <App/>
            </HelmetProvider>
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
