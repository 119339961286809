import React, {useEffect, useState} from 'react';
import axios from "axios";
import Layout from "../components/Layout";
import ArchivedSlide from "../components/ArchivedSlide";
import {getUserToken} from "../utils/helpers";
import LoadingGif from "../components/LoadingGif";
import ActionMessage from "../components/ActionMessage";
import SEO from "../components/SEO";

const Archive = () => {

    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [restoreMessage, setRestoreMessage] = useState(false);

    const getArchivedSlides = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/archive`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
            });

            if (response.data) {
                setSlides(response.data);
            }

            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    const restoreSlide = async (id) => {
        try {
            const response = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/dias/restore/${id}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
            });

            if (response.data) {
                setSlides(response.data);
            }

            getArchivedSlides();

            setRestoreMessage(true);

        } catch (e) {
            console.log(e);
        }
    }

    const handleDelete = async (e, id) => {
        e.preventDefault();

        try {
            const deleteResponse = await axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_URL}/dias/${id}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });

            if (deleteResponse.status === 200) {
                window.scrollTo(0, 0);
                setDeleteMessage(true);
            }

            getArchivedSlides();

            setDeleteMessage(true);

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            setDeleteMessage(false);
        }, 3000)
        return () => {
            clearTimeout(timer);
        }
    }, [deleteMessage])

    useEffect(() => {
        let timer = setTimeout(() => {
            setRestoreMessage(false);
        }, 3000)
        return () => {
            clearTimeout(timer);
        }
    }, [restoreMessage])

    useEffect(() => {
        getArchivedSlides();
    }, [])

    return (
        <Layout>
            <SEO title="My Archive" description="Safely store your slides without deleting them"/>
            <div className="flex-grow bg-gray-900">

                {
                    deleteMessage &&
                    <ActionMessage
                        message={"Slide has been removed"}
                        title={"Deleted"}
                        color={"red"}/>
                }

                {
                    restoreMessage &&
                    <ActionMessage
                        message={"Slide has been restored"}
                        title={"Restored"}
                        color={"green"}/>
                }

                {
                    loading &&
                    <LoadingGif/>
                }

                {
                    slides.length < 1 &&
                    <div className="h-full flex items-center">
                        <div className="w-full lg:max-w-full lg:flex justify-center">
                            <p className="text-white text-4xl">
                                Archive Empty
                            </p>
                        </div>
                    </div>
                }

                <div className="flex w-full flex-wrap">
                    {
                        slides.map((slide) => {
                            return (
                                <ArchivedSlide
                                    key={slide.id}
                                    image={slide.image}
                                    restoreSlide={() => restoreSlide(slide.id)}
                                    handleDelete={(e) => handleDelete(e, slide.id)}/>
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    );
};

export default Archive;
