import React from 'react';
import ReactMarkdown from "react-markdown";

const Rule = ({rule}) => {
    return (
        <div className="rule-section">
            <h1 className="rule-section-title">
                {rule.title}
            </h1>
            <ReactMarkdown className="markdown-text">
                {rule.description}
            </ReactMarkdown>
        </div>
    );
};

export default Rule;
