import React, {useRef, useState} from 'react';
import {usePopper} from "react-popper";
import {AiFillQuestionCircle} from "react-icons/ai";

const BtnPopClick = ({title, description, placement}) => {

    const boxRef = useRef();
    const tooltipRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const {styles, attributes} = usePopper(boxRef.current, tooltipRef.current, {
        placement: placement,
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0,10]
                }
            },
        ]
    });

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className="appearance-none border-none">
                    <button
                        className="w-full py-2 px-4 text-gray-700 focus:outline-none"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => {
                            handleClick()
                        }}
                        ref={boxRef}>
                        <AiFillQuestionCircle className="text-2xl"/>
                    </button>
                    <div
                        className={
                            (isOpen ? "" : "hidden ") +
                            "bg-teal-600 block z-50 font-normal leading-normal " +
                            "text-sm max-w-xs text-left no-underline break-words rounded-lg"}
                        ref={tooltipRef}
                        {...attributes.popper}
                        style={styles.popper}>
                        <div>
                            <div
                                className={
                                    "bg-teal-600 text-center text-white opacity-75 font-semibold border-b " +
                                    "border-solid border-gray-200 uppercase rounded-t-lg"}>
                                {title || "Title"}
                            </div>
                            <div className="text-white p-3">
                                {description || "Description"}
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
};

export default BtnPopClick;
