import React from "react";
import ReactPlayer from "react-player";

export default function Modal({setShowModal, title, description, video, answer}) {

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0
                        z-50 outline-none focus:outline-none"
                onClick={() => setShowModal(false)}>
                <div className="relative w-auto my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white
                            outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300
                                rounded-t">
                            <h3 className="text-3xl font-semibold text-black">
                                {title}
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right
                                         text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}>
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block
                                            outline-none focus:outline-none">
                                              ×
                                            </span>
                            </button>
                        </div>
                        {/*body*/}
                        {
                            video &&
                            <div className="relative p-6 flex-auto">
                                <ReactPlayer url={video} controls={false} muted={true} playing={true}
                                             loop={true} width={"100%"} height={"60%"}/>
                            </div>
                        }
                        {
                            answer &&
                            <div className="relative p-6 flex-auto">
                                <p className="text-black leading-8">
                                    {answer}
                                </p>
                            </div>
                        }
                        {/*footer*/}
                        <div className="flex items-center justify-between p-6 border-t border-solid border-gray-300
                                rounded-b">
                            <span className="font-bold text-black">{description}</span>
                            <button
                                className="box-border text-red-500 background-transparent font-bold uppercase px-6 py-2
                                        text-sm outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                style={{transition: "all .15s ease"}}
                                onClick={() => setShowModal(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black">
            </div>
        </>
    );
}