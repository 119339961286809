import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Rule from "../components/Rule";
import axios from "axios";
import {getUserToken} from "../utils/helpers";

const Home = () => {

    const [heroSection, setHeroSection] = useState({});
    const [ruleSection, setRuleSection] = useState({});
    const [rules, setRules] = useState([]);
    const [loading, setLoading] = useState(null);

    const getHomePage = async () => {
        setLoading(true);
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/home-page`, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }
        );

        if (response.data.heroSection) {
            setHeroSection(response.data.heroSection);
        }
        if (response.data.ruleSection) {
            setRuleSection(response.data.ruleSection);
            setRules(response.data.ruleSection.rules);
        }

        setLoading(false);
    }

    useEffect(() => {
        getHomePage();
    }, [])

    return (
        <Layout>
            <SEO title="Home Page" description="Welcome"/>

            <div className="flex-grow bg-gray-900">

                {
                    !loading &&
                    <>
                        <div className="home-hero">
                            <h1 className="home-main-title">
                                {heroSection.title}
                            </h1>

                            <h3 className="home-main-description">
                                {heroSection.description}
                            </h3>
                        </div>

                        <div className="rules-section">
                            <div className="section-title">
                                <h1>
                                    {ruleSection.title}
                                </h1>
                            </div>

                            {rules && rules.length > 0 &&
                            <div className="rules">
                                {
                                    rules.map((rule, index) => {
                                        return (
                                            <>
                                                <Rule rule={rule} key={index}/>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            }
                        </div>
                    </>
                }
            </div>
        </Layout>
    );
};

export default Home;
