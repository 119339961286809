import React, {useEffect, useState} from 'react';
import axios from "axios";
import Dia from "../components/Dia";
import {BsFillBackspaceFill} from "react-icons/bs"
import {getUserToken} from "../utils/helpers";
import SEO from "../components/SEO";

const DiaPreview = ({match, history}) => {

    const [dia, setDia] = useState({});
    const [image, setImage] = useState({});

    const {id} = match.params;

    const getDia = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/dias/${id}`;

            const diaResponse = await axios({
                method: "GET",
                url,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
            const data = await diaResponse.data;

            setDia(data);
            setImage(data.image);

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDia();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="relative h-screen">
            <SEO title="Slide Preview" description="A preview of this slide"/>
            <div className="top-0 left-0 absolute text-4xl ml-6 hover:text-gray-700 hover:scale-110 transform duration-500 z-10">
                <button onClick={() => history.push("/dias")}>
                    <BsFillBackspaceFill/>
                </button>
            </div>
            <Dia
                title={dia.title}
                image={image}
                description={dia.description}
                playing={true}/>
        </div>
    );
};

export default DiaPreview;
