import React from 'react';
import loadingGif from "../img/loadingGif.gif";

const LoadingGif = () => {
    return (
        <div className="h-full flex items-center">
            <div className="w-full lg:max-w-full lg:flex justify-center">
                <p className="text-white text-4xl">
                    <img src={loadingGif} alt="loading" className="w-24 h-24"/>
                </p>
            </div>
        </div>
    );
};

export default LoadingGif;
