import React from "react";

export default function Modal({handleDeleteSubmit, removeModal, diaId}) {

    return (
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => removeModal(false)}>
            <div className="relative w-auto my-6 mx-auto max-w-sm">

                <div
                    className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                    <div
                        className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                        <h3 className="text-3xl font-semibold text-gray-800">
                            Delete Slide
                        </h3>
                        <button
                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => removeModal(false)}>
                                <span
                                    className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                  ×
                                </span>
                        </button>
                    </div>

                    <div className="relative p-6 flex-auto">
                        <p className="my-4 text-gray-600 text-lg leading-relaxed">
                            Are you sure?
                        </p>
                    </div>

                    <div
                        className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm
                                outline-none focus:outline-none mr-1 mb-1 opacity-75 hover:opacity-100 transition duration-500"
                            type="button"
                            onClick={() => removeModal(false)}>
                            Cancel
                        </button>
                        <button
                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6
                                py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
                                opacity-75 hover:opacity-100 transition duration-500"
                            type="submit"
                            onClick={(e) => {
                                removeModal(false);
                                handleDeleteSubmit(e, diaId);
                            }}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}