import React from 'react';

const DropDown = ({getSlides, getInactiveSlides, getActiveSlides}) => {

    const [openTab, setOpenTab] = React.useState(1);
    const color = "teal"

    return (
        <>
            <div className="flex bg-gray-900 justify-center content-around">
                <div className="w-full">
                    <ul className="flex justify-center mb-0 list-none flex-wrap pt-1 pb-1 flex-row"
                        role="tablist">
                        <li className="-mb-px mr-2 last:mr-0 flex justify-center text-center">
                            <button className={
                                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal focus:outline-none " +
                                    (openTab === 1
                                        ? "text-white bg-" + color + "-600"
                                        : "text-" + color + "-600 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                    getSlides();
                                }}>
                                Show All
                            </button>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex text-center">
                            <button
                                className={
                                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal focus:outline-none " +
                                    (openTab === 2
                                        ? "text-white bg-" + color + "-600"
                                        : "text-" + color + "-600 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                    getActiveSlides();
                                }}>
                                Show Active
                            </button>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex text-center">
                            <button
                                className={
                                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal focus:outline-none " +
                                    (openTab === 3
                                        ? "text-white bg-" + color + "-600"
                                        : "text-" + color + "-600 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(3);
                                    getInactiveSlides();
                                }}>
                                Show Inactive
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default DropDown;
