import React from 'react';

const CenterMessage = ({text}) => {
    return (
        <div className="h-full flex items-center">
            <div className="w-full lg:max-w-full lg:flex justify-center">
                <p className="text-white text-4xl">
                    {text}
                </p>
            </div>
        </div>
    );
};

export default CenterMessage;
