import React, {useEffect, useState} from 'react';
import axios from "axios";
import Ref404 from "./Ref404";

const NotFound = ({history}) => {

    const [notFoundPage, setNotFoundPage] = useState({});
    const [loading, setLoading] = useState(true);
    const [image404, setImage404] = useState("");
    const [logo, setLogo] = useState("");
    const [links, setLinks] = useState([]);
    const [isHovering, setIsHovering] = useState({});

    const source = axios.CancelToken.source();

    const getNotFoundPage = async () => {

        try {
            const dataResponse = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/page-404`,
                cancelToken: source.token
            });

            const data = await dataResponse.data;

            console.log(data.link[0].text);

            setImage404(data.image404[0].url);
            setLogo(data.logo[0].url);
            setLinks(data.link);
            setNotFoundPage(data);
            setLoading(false);
        } catch (error) {
            if (axios.isCancel(error)) {

            } else {
                console.log(error);
            }
        }
    }

    const enter = (index) => {
        setIsHovering({[index]: true})
    }

    const leave = (index) => {
        setIsHovering({[index]: false})
    }

    useEffect(() => {
        const token = (JSON.parse(localStorage.getItem("jwt")));
        if (!token) {
            return history.push("/login");
        }

        getNotFoundPage();

        return () => {
            source.cancel();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex flex-col items-center justify-center min-h-full">
            {
                loading &&
                <div>

                </div>
            }
            {
                !loading &&
                <>
                    <div className="m-12">
                        <img src={image404} alt="error404logo" className="max-w-sm"/>
                    </div>
                    <div>
                        <div>
                            <h1 className="text-4xl text-center font-normal leading-normal mt-0 mb-2 text-indigo-800">
                                {notFoundPage.title}
                            </h1>
                        </div>
                        <div>
                            <h2 className="text-lg font-light leading-relaxed mt-6 mb-4 text-indigo-800">
                                {notFoundPage.subtitle}
                            </h2>
                        </div>
                    </div>
                    <div className="">
                        <ul className="flex flex-col justify-start">
                            {
                                links &&
                                links.map((item, index) => {
                                    return (
                                        <Ref404
                                            onMouseEnter={() => enter(index)}
                                            onMouseLeave={() => leave(index)}
                                            isHovering={isHovering[index]}
                                            key={item.id}
                                            myLinkRef={item.ref}
                                            text={item.text}/>
                                    )
                                })
                            }
                        </ul>
                    </div>

                </>
            }
            <div className="flex justify-center sm:justify-end sm:absolute sm:bottom-0 sm:right-0 p-20">
                <img src={logo} alt="logo" className="w-2/4"/>
            </div>
        </div>

    );
};

export default NotFound;
