import React from 'react';

const ActionMessage = ({color, title, message}) => {
    return (
        <div>
            <div className={`bg-${color}-900 text-center py-4 lg:px-4`}>
                <div
                    className={`p-2 bg-${color}-800 items-center text-white leading-none lg:rounded-full flex lg:inline-flex`}
                    role="alert">
                        <span
                            className={`flex rounded-full bg-${color}-500 uppercase px-2 py-1 text-xs font-bold mr-3`}>{title}</span>
                    <span className="font-semibold mr-2 text-left flex-auto">{message}</span>
                </div>
            </div>
        </div>
    );
};

export default ActionMessage;
