import React from 'react';
import axios from "axios";
import ReactPlayer from "react-player";
import {getImgURL, getUserToken, isVideo} from "../utils/helpers";

const InactiveDia = ({image, duration, title, presentationId, diaId, dias, getInactiveDias, index, setShowAddMessage}) => {

    const handleAddSubmit = async (e) => {
        e.preventDefault();

        try {
            const updateResponse = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/presentations/slide/add/${presentationId}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                data: {
                    id: diaId,
                    dias: dias,
                    index: index,
                }
            });

            if (updateResponse.status === 200) {
                getInactiveDias();
                setShowAddMessage(true);
                window.scrollTo(0, 0);
            }

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="flex items-center justify-center my-8">
            {
                !isVideo(image.mime) &&
                <div className="bg-cover bg-center h-auto text-white py-24 px-10 object-fill w-3/4"
                     style={{backgroundImage: `url(${getImgURL(image.url)})`}}>
                    <div className="md:w-1/2 p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                        <p className="font-bold text-sm uppercase">{duration} second(s)</p>
                        <p className="text-3xl font-bold">{title}</p>
                        <form onSubmit={handleAddSubmit}>
                            <button type="submit"
                                    className="bg-transparent hover:bg-green-400 text-white hover:text-black
                            font-semibold py-1 px-4 border border-green-500 hover:border-transparent rounded transition duration-500 uppercase">
                                Add slide to presentation
                            </button>
                        </form>
                    </div>
                </div>
            }
            {
                isVideo(image.mime) &&
                <div className="bg-cover bg-center h-auto text-white px-10 object-fill w-3/4 py-2 relative">
                    <video className="bg-gray-300 h-64 w-full rounded-lg shadow-md bg-cover object-fill">
                        <source src={getImgURL(image.url)}/>
                    </video>
                    <div className="md:w-1/2 p-4 flex absolute inner-active-slide" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                        <div className="flex flex-col  mr-auto">
                            <p className="font-bold text-sm uppercase">{duration} second(s)</p>
                            <p className="text-3xl font-bold">{title}</p>
                            <p className="text-3xl font-bold">{image.ext}</p>
                            <form onSubmit={handleAddSubmit}>
                                <button type="submit"
                                        className="bg-transparent hover:bg-green-400 text-white hover:text-black
                            font-semibold py-1 px-4 border border-green-500 hover:border-transparent rounded transition duration-500 uppercase">
                                    Add slide to presentation
                                </button>
                            </form>
                        </div>
                        <div className="h-full flex justify-end">
                            <ReactPlayer url={image.url} width={100} height={100}/>
                        </div>
                    </div>
                </div>
            }

        </div>

    );
};

export default InactiveDia;
