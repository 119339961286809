import React, {useState} from 'react';
import {GiHamburgerMenu} from "react-icons/gi";
import {AiOutlineClose} from "react-icons/ai";

const SideBar = ({onNameChange}) => {

    const [showSidebar, setShowSidebar] = useState(true);
    const [activeName, setActiveName] = useState("basic");

    return (
        <div className="md:flex flex-col md:flex-row h-full sidebar">

            <button className={`text-white sidebar-toggle text-teal-200 hover:text-teal-400 hover:scale-110 transform duration-500
                ${showSidebar ? "hidden" : ""}`} onClick={() => {
                setShowSidebar(!showSidebar);
            }}>
                <GiHamburgerMenu/>
            </button>

            <div className={`relative flex flex-col md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 border-r" +
                    ${showSidebar ? "sidebar-open" : "sidebar-closed"}`}>

                <button className="" onClick={() => setShowSidebar(!showSidebar)}>
                    <AiOutlineClose
                        className="sidebar-close-btn absolute right-0 text-red-400 hover:text-red-600 m-2 overflow-hidden"/>
                </button>

                <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
                    <p
                        className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg
                           dark-mode:text-white focus:outline-none focus:shadow-outline">
                        Templates
                    </p>
                </div>
                <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
                    <button
                        className={`block items-start px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg w-full focus:outline-none
                            ${activeName === "basic" ? "bg-teal-400" : ""}`}
                        onClick={() => {
                            setActiveName("basic");
                            onNameChange("basic");
                        }}>
                        Basic
                    </button>
                    <button
                        className={`block items-start px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg w-full focus:outline-none
                            ${activeName === "image" ? "bg-teal-400" : ""}`}
                        onClick={() => {
                            setActiveName("image");
                            onNameChange("image");
                        }}>
                        Single Item
                    </button>
                    {/*<button*/}
                    {/*    className={`block items-start px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg w-full focus:outline-none*/}
                    {/*        ${activeName === "twoColumns" ? "bg-teal-400" : ""}`}*/}
                    {/*    onClick={() => {*/}
                    {/*        setActiveName("twoColumns");*/}
                    {/*        onNameChange("twoColumns");*/}
                    {/*    }}>*/}
                    {/*    Two Columns*/}
                    {/*</button>*/}
                </nav>
            </div>
        </div>
    );
};

export default SideBar;
