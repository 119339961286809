import React, {useContext, useRef, useState} from 'react';
import BtnPopClick from "./BtnPopClick";
import {AiOutlineCheckSquare, AiOutlineFileImage} from "react-icons/ai";
import {UserContext} from "../context/UserContext";
import axios from "axios";
import basicPreview from "../img/basic-preview.png"
import {getMaxFileSizeInMb, getUserToken, isVideo, validateFileSize} from "../utils/helpers";
import FormImagePreview from "./FormImagePreview";
import CreatingGif from "./CreatingGif";
import FormVideoPreview from "./FormVideoPreview";

const BasicForm = ({history}) => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selected, setSelected] = useState(false);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [duration, setDuration] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [fileToLarge, setFileToLarge] = useState(false);

    const formRef = useRef();

    const {user} = useContext(UserContext);

    const handleImagePreview = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setPreviewImage(undefined)
            return;
        }

        setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (fileToLarge) {
            setError(`File is to large! Maximum file size is ${getMaxFileSizeInMb()} MB`);
            return;
        }

        if (duration < 1) {
            setError("Please specify a duration in seconds");
            return;
        }

        if (!selected) {
            setError("Please upload an image");
            return;
        }

        setSubmitted(true);

        const data = {
            title: title,
            description: description,
            duration: (duration * 1000),
            user: user
        }

        const formData = new FormData();
        formData.append("files.image", file);
        formData.append('data', JSON.stringify(data));

        try {
            const createResponse = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/dias`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                data: formData
            });

            if (createResponse.status === 200) {
                history.push("/dias");
            }
        } catch (e) {
            setError(e.response.data.message);
        }
    }

    return (
        <div className="w-2/4">
            {
                !submitted &&
                <>
                    <form className="container mx-auto max-w-lg" onSubmit={handleSubmit} ref={formRef}
                          encType="multipart/form-data">
                        {
                            error &&
                            <div role="alert">
                                <div
                                    className="border border-red-400 border-l-8 bg-red-100 px-4 py-1 mb-3 text-red-700">
                                    <p>{error}</p>
                                </div>
                            </div>
                        }
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                                <label
                                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Title
                                </label>
                            </div>
                            <div className="md:w-2/3">
                                <input
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded
                                w-full mt-4 py-2 px-4 text-gray-700 leading-tight focus:outline-none
                                focus:bg-white focus:border-purple-500"
                                    placeholder="Awesome title" type="text" value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setError("");
                                    }}/>
                            </div>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                                <label
                                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Duration
                                </label>
                            </div>

                            <div className="md:w-1/3">
                                <input
                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded
                                w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
                                focus:bg-white focus:border-purple-500 appearance-none"
                                    type="number" value={duration} pattern="[0-9]*"
                                    onChange={(e) => {
                                        setDuration(parseInt(e.target.value));
                                        setError("");
                                    }}/>
                            </div>

                            <div className="md:w-1/3 invisible md:visible">
                                <BtnPopClick
                                    title={"Slide Duration"}
                                    description={"The duration in seconds you want to display this slide in the presentation."}
                                    placement={"right"}/>
                            </div>
                        </div>
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                                <label
                                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Description
                                </label>
                            </div>
                            <div className="md:w-2/3">
                            <textarea
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded
                                w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
                                focus:bg-white focus:border-purple-500"
                                placeholder="Lorem Ipsum" value={description} style={{height: "20vh"}}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}/>
                            </div>
                        </div>
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Image
                                </label>
                            </div>
                            <div className="items-center justify-center bg-grey-lighter md:w-1/3">
                                <label
                                    className="flex flex-col items-center bg-white rounded
                                text-blue shadow-lg tracking-wide cursor-pointer hover:bg-blue">
                                <span className="bg-gray-200 appearance-none border-2 border-gray-200 rounded
                                w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none font-semibold
                                focus:bg-white focus:border-purple-500 flex justify-center items-center
                                hover:bg-green-400 hover:text-black hover:border-green-400
                                hover:scale-105 transition transform duration-500">
                                    <AiOutlineFileImage/> Select a file
                                </span>
                                    <input type='file' className="hidden" name="file"
                                           onChange={(e) => {
                                               setSelected(true);
                                               setError("");
                                               handleImagePreview(e);
                                               setFileToLarge(validateFileSize(e.target.files[0].size));
                                               setFile(e.target.files[0]);
                                           }}/>
                                </label>
                            </div>
                            {
                                selected &&
                                <div
                                    className="text-white flex items-center justify-center md:w-1/3 text-4xl text-green-500">
                                    <AiOutlineCheckSquare/>
                                </div>
                            }
                        </div>
                        {
                            selected && previewImage && !isVideo(file.type) &&
                            <FormImagePreview
                                text={"image preview"}
                                url={previewImage}/>
                        }
                        {
                            selected && previewImage && isVideo(file.type) &&
                            <FormVideoPreview
                                url={previewImage}/>
                        }
                        <div className="md:flex md:items-center">
                            <div className="md:w-1/3"></div>
                            <div className="md:w-2/3">
                                <button
                                    className="shadow bg-green-500 hover:bg-green-400
                                focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded my-4"
                                    type="submit">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="w-1/4 form-preview-img">
                        <img src={basicPreview} alt="preview" className="form-preview"/>
                    </div>
                </>
            }
            {
                submitted && !error &&
                <CreatingGif/>
            }
            {
                submitted && error &&
                <div className="h-screen w-full flex items-center">
                    <div className="w-full lg:max-w-full lg:flex justify-center">
                        <p className="text-red-500 text-4xl">
                            {error}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
};

export default BasicForm;
