import React, {useContext, useEffect, useState} from 'react';
import Layout from "../components/Layout";
import {UserContext} from "../context/UserContext";
import UserCard from "../components/UserCard";
import axios from "axios";
import {getUserToken} from "../utils/helpers";
import SEO from "../components/SEO";

const Admin = ({history}) => {

    const [users, setUsers] = useState([]);

    const {user} = useContext(UserContext);

    const getUsers = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/users`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });

            if (response.data) {
                setUsers(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (user.value.role.name !== "Admin") {
            history.push("/dias");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <Layout>
            <SEO title="Admin" description="Overview of users"/>
            {
                user.value.role.name === "Admin" && users &&
                <main className="flex-grow bg-gray-900">
                    {
                        users.map((user) => {
                            return (
                                <UserCard
                                    slides={user.dias}
                                    username={user.role.name}
                                    name={user.name}
                                    title={user.title}
                                    company={user.company}
                                    diaCount={user.dias.length}
                                    key={user.id}
                                    email={user.email}/>
                            )
                        })
                    }
                </main>
            }
        </Layout>
    );
};

export default Admin;
