import React from 'react';
import {Link} from "react-router-dom";
import {GrLinkNext} from "react-icons/gr";

const Ref404 = ({text, myLinkRef, onMouseEnter, onMouseLeave, isHovering}) => {

    return (
        <li className="">

            <Link className="flex align-baseline items-center text-2xl text-indigo-800 opacity-25 hover:opacity-100"
                  to={`${myLinkRef}`}
                  onMouseEnter={() => onMouseEnter()}
                  onMouseLeave={() => onMouseLeave()}>
                {isHovering && <GrLinkNext className="mr-4" id="icon" style={{}}/>}
                {text}
            </Link>
        </li>
    );
};

export default Ref404;
