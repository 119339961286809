import React from 'react';
import {getImgURL, isVideo} from "../utils/helpers";

const ArchivedSlide = ({image, restoreSlide, handleDelete}) => {

    return (
        <div className="flex flex-col justify-center items-center max-w-xl mx-auto my-8 w-full">
            {
                image && !isVideo(image.mime) &&
                <div className="bg-gray-300 h-64 w-full rounded-lg shadow-md bg-cover bg-center"
                     style={{backgroundImage: `url(${getImgURL(image.url)})`}}>
                </div>
            }
            {
                image && isVideo(image.mime) &&
                <video className="bg-gray-300 h-64 w-full rounded-lg shadow-md bg-cover object-fill">
                    <source src={getImgURL(image.url)}/>
                </video>
            }
            <div className="w-56 md:w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden z-10">
                <div className="py-2 text-center font-bold uppercase tracking-wide text-gray-800">{image.ext}</div>
                <div className="flex items-center justify-between py-2 px-3 bg-gray-400">
                    <button
                        className=" bg-red-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700"
                        onClick={(e) => {
                            handleDelete(e);
                        }}>
                        Delete
                    </button>
                    <button
                        className=" bg-green-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700"
                        onClick={() => {
                            restoreSlide();
                        }}>
                        Restore
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ArchivedSlide;
