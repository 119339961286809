import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import axios from "axios";
import InactiveDia from "../components/InactiveDia";
import {Link} from "react-router-dom";
import {getUserToken} from "../utils/helpers";
import LoadingGif from "../components/LoadingGif";
import SEO from "../components/SEO";
import ActionMessage from "../components/ActionMessage";

const AddDiaToPresentation = () => {

    const [presentation, setPresentation] = useState({});
    const [inactiveDias, setInactiveDias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showAddMessage, setShowAddMessage] = useState(false);

    const getPresentation = async () => {
        const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/presentations/user`,
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            },
        });
        setPresentation(await response.data[0]);
    }

    const getInactiveDias = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/dias/inactive`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
            });

            if (response) {
                setInactiveDias(response.data);
                setLoading(false);
            }
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            setShowAddMessage(false);
        },3000)
        return () => {
            clearTimeout(timer);
        }
    }, [showAddMessage])

    useEffect(() => {
        getInactiveDias();
    }, [])

    useEffect(() => {
        getPresentation();
    }, [])

    return (
        <Layout>
            <SEO title="Presentation Add Slides" description="Add slides to your presentation"/>
            {
                showAddMessage &&
                <ActionMessage
                    color={"green"}
                    title={"Added"}
                    message={"Slide has been added to your presentation"}/>
            }
            <div className="flex-grow bg-gray-900">
                {
                    loading &&
                    <LoadingGif/>
                }
                {
                    !loading && error &&
                    <div className="h-full flex items-center">
                        <div className="w-full lg:max-w-full lg:flex justify-center">
                            <div>
                                <p className="text-white text-4xl">
                                    No more Slides found </p>
                                <hr className="my-4"/>
                                <p className="text-white text-4xl">Create a new one <Link to={"/dias"} className="border-b
                                hover:text-blue-600 text-blue-400 hover:scale-110 transform transition duration-500">HERE</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                }
                {
                    !loading && !error &&
                    <>
                        {
                            inactiveDias.length > 0 &&
                            <>
                                {
                                    inactiveDias.map((dia, index) => {
                                        return (
                                            <InactiveDia
                                                key={dia.id}
                                                duration={dia.duration / 1000}
                                                title={dia.title}
                                                image={dia.image}
                                                diaId={dia.id}
                                                presentationId={presentation.id}
                                                getInactiveDias={() => getInactiveDias()}
                                                dias={inactiveDias}
                                                index={index}
                                                setShowAddMessage={() => setShowAddMessage(true)}/>
                                        )
                                    })
                                }
                            </>
                        }
                    </>
                }
            </div>
        </Layout>
    );
};

export default AddDiaToPresentation;
