import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import {Link} from "react-router-dom";
import axios from "axios";
import ActiveDia from "../components/ActiveDia";
import {IoMdAdd} from "react-icons/io";
import {AiOutlineUnorderedList} from "react-icons/ai"
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {getUserToken} from "../utils/helpers";
import LoadingGif from "../components/LoadingGif";
import ActionMessage from "../components/ActionMessage";
import CenterMessage from "../components/CenterMessage";
import SEO from "../components/SEO";

const EditPresentation = () => {

    const [loading, setLoading] = useState(true);
    const [presentation, setPresentation] = useState({});
    const [dias, setDias] = useState([]);
    const [availableDuration, setAvailableDuration] = useState(0);
    const [usedDuration, setUsedDuration] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [dragged, setDragged] = useState(false);
    const [savedChanges, setSavedChanges] = useState(false);
    const [showRemoveMessage, setShowRemoveMessage] = useState(false);

    const getPresentation = async () => {
        try {
            const dataResponse = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/presentations/user`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
            const data = await dataResponse.data;

            setAvailableDuration(data[0].duration/1000);

            calculateUsedDuration(data[0].dias, data[0].duration);

            setPresentation({id: data[0].id, duration: data[0].duration});
            setDias(data[0].dias);

            localStorage.setItem("dias", JSON.stringify(data[0].dias));
            setLoading(false);
        } catch (e) {
            setErrorMessage("Try adding a Slide to your Presentation");
        }
    }

    const calculateUsedDuration = (dias, availableDuration) => {
        let duration = 0;
        for (let i = 0; i < dias.length; i++) {
            duration += dias[i].duration
        }
        if ((duration) > availableDuration) {
            setErrorMessage(`Available time exceeded ${duration/1000}/${availableDuration/1000}. 
            Please change the duration of your active slides.`)
        }
        setUsedDuration(duration/1000);
    }

    const onDragEnd = (result) => {
        const {destination, source} = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const content = reorder(dias, source.index, destination.index)

        setDias(content);
        setDragged(true);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const saveDragChanges = async (e) => {
        e.preventDefault();

        for (let i = 0; i < dias.length; i++) {
            dias[i].position = i+1;
        }

        try {
            const response = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/dias/all/${presentation.id}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                data: dias
            });

            if (response.status === 200) {
                window.scrollTo(0, 0);
                setSavedChanges(true);
                setDragged(false);
                getPresentation();
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            setSavedChanges(false);
            setShowRemoveMessage(false);
        },3000)
        return () => {
            clearTimeout(timer);
        }
    }, [savedChanges, showRemoveMessage])

    useEffect(() => {
        getPresentation();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout>
            <SEO title="My Presentation" description="Add, remove and order your slides"/>
            {
                savedChanges &&
                    <ActionMessage
                    color={"green"}
                    title={"Success"}
                    message={"Changes are saved!"}/>
            }
            {
                showRemoveMessage &&
                <ActionMessage
                    color={"red"}
                    title={"Removed"}
                    message={"Slide has been removed from your presentation"}/>
            }
            <div className="flex-grow bg-gray-900">
                {
                    errorMessage &&
                    <CenterMessage
                    text={errorMessage}/>
                }
                {
                    loading &&
                    <LoadingGif/>
                }
                {
                    !loading && !errorMessage && dias.length > 0 &&
                    <>
                        <div className="flex items-center justify-center mt-6">
                            <Link className="bg-transparent bg-blue-300 hover:bg-blue-500 text-black font-semibold
                                hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded
                                transition duration-500" to={"/dias/presentation/preview/show"}>
                                Presentation Preview !
                            </Link>
                        </div>
                        <div>
                            <p className="font-mono text-3xl mt-12 text-white text-center">
                                My current active Slides
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="font-mono text-xl mt-2 text-white text-center">
                                Used time: {usedDuration} / {availableDuration}
                            </p>
                        </div>
                        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            <Droppable droppableId={presentation.id + ""}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            dias.map((dia, index) => {
                                                return (
                                                    <ActiveDia
                                                        index={index}
                                                        key={dia.id}
                                                        image={dia.image}
                                                        title={dia.title}
                                                        duration={dia.duration / 1000}
                                                        presentationId={presentation.id}
                                                        diaId={dia.id}
                                                        dias={dias}
                                                        getPresentation={() => getPresentation()}
                                                        setShowRemoveMessage={() => setShowRemoveMessage(true)}/>
                                                )
                                            })
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                }
                <div className="flex flex-wrap justify-evenly">
                    {
                        <div className="mb-6 mt-6">
                            <Link to={{pathname: `/dias/presentation/${presentation.id}`, state: {data: presentation}}}>
                                <button
                                    className="bg-green-300 hover:bg-green-600 hover:text-white text-gray-800 font-bold py-2 px-4 rounded
                                    text-xl inline-flex items-center transition duration-500">
                                    <IoMdAdd size={24} className="mr-2"/>
                                    <span>Add Slide</span>
                                </button>
                            </Link>
                        </div>
                    }
                    {
                        !errorMessage && dragged &&
                        <div className="mb-6">
                            <form onSubmit={(e) => saveDragChanges(e)}>
                                <button type="submit"
                                        className="bg-teal-300 hover:bg-teal-600 hover:text-white text-gray-800 font-bold
                                        py-2 px-4 rounded text-xl inline-flex items-center transition duration-500">
                                    <AiOutlineUnorderedList size={24} className="mr-2"/>
                                    <span>Save Changes</span>
                                </button>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default EditPresentation;
