import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Login from "./pages/login";
import {UserContext} from "./context/UserContext";
import Dias from "./pages/dias";
import DiaPreview from "./pages/diaPreview";
import CreateDia from "./pages/createDia";
import DiaPresentation from "./pages/diaPresentation";
import EditDia from "./pages/editDia";
import EditPresentation from "./pages/editPresentation";
import AddDiaToPresentation from "./pages/addDiaToPresentation";
import Admin from "./pages/admin";
import NotFound from "./components/NotFound";
import Help from "./pages/help";
import Archive from "./pages/Archive";
import Live from "./pages/live";
import Introduction from "./pages/introduction";
import Home from "./pages/Home";

function App() {

    const [token, setToken] = useState("");
    const {setUser} = useContext(UserContext);

    useEffect(() => {
        setToken(localStorage.getItem("jwt"));
        const user = localStorage.getItem("user");
        setUser(JSON.parse(user));
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BrowserRouter>
            {
                !token &&
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/live" exact component={Live}/>
                    <Route path="*" component={NotFound}/>
                </Switch>
            }

            {
                token &&
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/dias" exact component={Dias}/>
                    <Route path="/dias/preview/:id" exact component={DiaPreview}/>
                    <Route path="/dias/create" exact component={CreateDia}/>
                    <Route path="/dias/edit/:id" exact component={EditDia}/>
                    <Route path="/dias/presentation" exact component={EditPresentation}/>
                    <Route path="/dias/presentation/preview/show" exact component={DiaPresentation}/>
                    <Route path="/dias/presentation/:id" exact component={AddDiaToPresentation}/>
                    <Route path="/admin" exact component={Admin}/>
                    <Route path="/help" exact component={Help}/>
                    <Route path="/archive" exact component={Archive}/>
                    <Route path="/introduction" exact component={Introduction} />
                    <Route path="/live" exact component={Live}/>
                    <Route path="*" component={NotFound}/>
                </Switch>
            }
        </BrowserRouter>
    );
}

export default App;
