import React, {useState} from 'react';
import office from "../img/office.jpg"
import login_bg from "../img/login-bg.jpeg"
import logo from "../img/logo.png"
import axios from "axios";

const LoginComponent = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Submitted")

        const data = {
            identifier: username,
            password: password
        };

        try {
            const loginResponse = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/auth/local`,
                data: data
            });
            const responseData = await loginResponse.data;

            const expiry = new Date();
            expiry.setDate(expiry.getDate() + 1);
            const userItem = {
                value: responseData.user,
                expiry: expiry
            }
            const tokenItem = {
                value: responseData.jwt,
                expiry: expiry
            }

            localStorage.setItem("user", JSON.stringify(userItem));
            localStorage.setItem("jwt", JSON.stringify(tokenItem));

            window.location = "/";

        } catch (error) {
            let loginError = error.response ?
                error.response.data.data[0].messages[0].message : "API is offline";
            setLoginError(loginError);
        }
    }

    return (
        <div className="h-screen flex items-center"
             style={{backgroundImage: `url(${login_bg})`, backgroundSize: "cover",
                 backgroundBlendMode: "luminosity", backgroundColor: "rgba(0,0,0,0.5)"}}>
            <div className="w-full lg:max-w-full lg:flex justify-center">
                <div
                    className="h-48 lg:h-auto lg:w-1/4 flex-none bg-cover rounded-t
                    lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
                    style={{backgroundImage: `url(${office})`}} title="AA login">
                </div>
                <div
                    className="border-r border-b border-l border-gray-400 lg:border-l-0
                    lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none
                    lg:rounded-r p-4 flex flex-col justify-between leading-normal lg:w-2/4">
                    <div className="mb-8">
                        <p className="text-sm text-gray-600 flex items-center">
                            <svg className="fill-current text-gray-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20">
                                <path
                                    d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2
                                2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2
                                0zM7 6v2h6V6a3 3 0 0 0-6 0z"/>
                            </svg>
                            Members only
                        </p>
                    </div>

                    <form className="flex flex-col items-start w-full max-w-screen-xl" onSubmit={handleSubmit}>
                        <div className="md:flex md:items-center mb-6 w-full">
                            <div className="">
                                <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
                                       htmlFor="inline-full-name">
                                   Username
                                </label>
                            </div>
                            <div className="md:w-2/3">
                                <input
                                    className="bg-gray-200 appearance-none border-2 border-gray-200
                                     rounded w-full py-2 px-4 text-gray-700 leading-tight
                                      focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="username" type="text" placeholder="Richard" value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                    setLoginError("");
                                }}/>
                            </div>
                        </div>
                        <div className="md:flex md:items-center mb-6 w-full">
                            <div className="">
                                <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
                                       htmlFor="inline-password">
                                    Password
                                </label>
                            </div>
                            <div className="md:w-2/3">
                                <input
                                    className="bg-gray-200 appearance-none border-2 border-gray-200
                                    rounded w-full py-2 px-4 text-gray-700 leading-tight
                                    focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="password" type="password" placeholder="******************" value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setLoginError("");
                                }}/>
                            </div>
                        </div>

                        <div>
                            {
                                loginError &&
                                <div role="alert">
                                    <div
                                        className="border border-red-400 border-l-8 bg-red-100 px-4 py-1 mb-3 text-red-700">
                                        <p>{loginError}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="md:flex md:items-center w-full">
                            <div className="md:w-2/3">
                                <button
                                    className="shadow bg-blue-500 hover:bg-blue-700
                                    focus:shadow-outline focus:outline-none text-white
                                    font-bold mb-4 py-2 px-8 rounded transition duration-1000"
                                    type="submit">
                                    Login
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="flex items-center">
                        <img className="w-10 h-10 rounded-full mr-4" src={logo}
                             alt="Avatar of Jonathan Reinink"/>
                        <div className="text-sm">
                            <p className="text-gray-900 leading-none">Impulsum Group</p>
                            <p className="text-gray-600">2020</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;
