import React from 'react';
import savingGif from "../img/savingGif.gif";

const SavingGif = () => {
    return (
        <div className="h-screen w-full flex items-center">
            <div className="w-full lg:max-w-full lg:flex justify-center">
                <p className="text-white text-4xl">
                    <img src={savingGif} alt="loading" className="w-24 h-24"/>
                </p>
            </div>
        </div>
    );
};

export default SavingGif;
