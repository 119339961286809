export const MAX_FILE_SIZE = process.env.REACT_MAX_FILE_SIZE || 10485760;

export const isVideo = (mimeType) => {
    return mimeType === "video/mp4"
}

export const getUserToken = () => {
    const token = JSON.parse(localStorage.getItem("jwt"));
    return token.value;
}

export const validateFileSize = (size) => {
    return size > MAX_FILE_SIZE;
}

export const getMaxFileSizeInMb = () => {
    return MAX_FILE_SIZE / Math.pow(1024,2);
}

export const getImgURL = (url) => {
    return url;
}

export const userHasExceededMaximumSlides = (slideCount) => {
    return slideCount >= process.env.REACT_APP_MAX_SLIDES_PER_USER;
}