import React, {useContext, useState} from 'react';
import axios from "axios";
import Layout from "../components/Layout";
import westItLogo from "../img/west-it.png"
import emailImg from "../img/email.jpeg"
import {UserContext} from "../context/UserContext";
import {getUserToken} from "../utils/helpers";
import SEO from "../components/SEO";

const Help = () => {

    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [send, setSend] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {user} = useContext(UserContext);

    const sendEmail = async (e) => {
        e.preventDefault();

        if (subject === "") {
            setErrorMessage("Please enter a subject");
            return;
        }
        if (message === "") {
            setErrorMessage("Please enter a message");
            return;
        }

        const editedMessage =
            `
                Email from: <strong>${user.value.email}</strong>. <br/><br/>
                <hr/>
                ${message}  
            `;

        const data = {
            to: "dxvictziqobitrhxde@avxrja.com",
            subject: subject,
            html: editedMessage
        }

        const response = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/email`,
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            },
            data: data
        });

        if (response.status === 200) {
            setSend(true);
        }
    }

    return (
        <Layout>
            <SEO title="Help" description="Any questions? Get your answers here"/>
            <div className="flex-grow bg-gray-900">
                {
                    send &&
                    <div className="bg-teal-900 text-center py-4 lg:px-4">
                        <div
                            className="p-2 bg-teal-800 items-center text-white leading-none lg:rounded-full flex lg:inline-flex"
                            role="alert">
                        <span
                            className="flex rounded-full bg-teal-500 uppercase px-2 py-1 text-xs font-bold mr-3">Send</span>
                            <span className="font-semibold mr-2 text-left flex-auto">Email has been sent. We'll get back to you ASAP!</span>
                        </div>
                    </div>
                }
                <div className="h-full flex justify-center items-center">
                    <div className="md:flex flex-col md:flex-row max-w-6xl bg-white h75 rounded-l-lg rounded-r-lg">
                        <div className="md:w-2/3 flex flex-col al">
                            <div className="pl-4 w-1/6">
                                <a href="https://www.west.nl/contact"
                                   className="text-white font-bold" target="_blank" rel="noreferrer noopener">
                                    <img src={westItLogo} alt="westIT logo" style={{width: 100, height: 100}}/>
                                </a>
                            </div>
                            <div className="flex flex-col justify-center md:justify-start md:pt-0 px-8 md:px-24 lg:px-32 overflow-y-auto">
                                {
                                    errorMessage &&
                                    <div role="alert">
                                        <div
                                            className="border border-red-400 border-l-8 bg-red-100 px-4 py-1 mb-3 text-red-700">
                                            <p>{errorMessage}</p>
                                        </div>
                                    </div>
                                }
                                <p className="text-center text-3xl">Questions?</p>
                                <form className="flex flex-col" onSubmit={(e) => sendEmail(e)}>
                                    <div className="flex flex-col pt-4">
                                        <label className="text-lg">Subject</label>
                                        <input placeholder="Help me!" onChange={(e) => {
                                            setSubject(e.target.value);
                                            setErrorMessage("");
                                        }}
                                               className="shadow appearance-none border rounded w-full py-2 px-3
                                               text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"/>
                                    </div>

                                    <div className="flex flex-col pt-4">
                                        <label className="text-lg">Message</label>
                                        <textarea className="shadow appearance-none border rounded w-full px-3 h-48
                                               text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline resize-none overflow-y-auto"
                                                  placeholder="Ask me anything"
                                                  onChange={(e) =>{
                                                      setMessage(e.target.value);
                                                      setErrorMessage("");
                                                  }}>
                                        </textarea>
                                    </div>
                                    <input type="submit" value="Send"
                                           className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2
                                           mt-8 cursor-pointer transition duration-500 mb-4"/>
                                </form>
                            </div>
                        </div>

                        <div className="md:w-1/2 flex shadow-2xl invisible md:visible">
                            <img className="object-cover rounded-r-lg"
                                 src={emailImg} alt="email im"/>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default Help;
