import React from 'react';

const FormImagePreview = ({url, text}) => {
    return (
        <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
                <img src={url} alt="preview" className="h-48 w-full"/>
                <span className="text-gray-100 opacity-25">{text}</span>
            </div>
        </div>
    );
};

export default FormImagePreview;
