import React from 'react';
import {Helmet} from "react-helmet-async";

const SEO = ({title, description, image}) => {
    return (
        <Helmet htmlAttributes={{ lang: "en" }} title={title}>
            <meta name="description" content={description}/>
            <meta name="image" content={image} />
        </Helmet>
    );
};

export default SEO;
