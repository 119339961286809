import React, {useState} from 'react';
import {FaQuestion} from "react-icons/fa";
import VideoModal from "./VideoModal";

const Question = ({question, color, title, description, video, answer}) => {

    const [showModal, setShowModal] = useState(false);
    const bgColor = color || "gray";

    return (
        <div className={"md:flex items-center content-end my-4 text-white px-6 py-4 border-0 rounded relative mb-4 bg-" + bgColor + "-500 md:w-1/3 m-auto"}>
                    <span className="flex inline-block align-middle mr-auto text-xl">
                        {question}
                    </span>
            <button className="p-6 w-12 h-12 bg-teal-600 rounded-full hover:bg-teal-700 active:shadow-lg relative
                            mouse shadow transition ease-in duration-200 focus:outline-none"
                    onClick={() => {
                        setShowModal(true);
                    }}>
                <FaQuestion className="w-6 h-6 text-white absolute absolute-center"/>
            </button>

            {
                showModal &&
                <VideoModal
                    title={title}
                    setShowModal={() => setShowModal(false)}
                    description={description}
                    video={video}
                    answer={answer}/>
            }
        </div>
    );
};

export default Question;
