import React, {useEffect} from 'react';
import LoginComponent from "../components/LoginComponent";
import SEO from "../components/SEO";

const Login = () => {

    useEffect(() => {
        if (localStorage.getItem("jwt") || localStorage.getItem("user")) {
            window.location = "/";
        }
    }, [])

    return (
        <div>
            <SEO title="Login" description="Login to enter the website"/>
            <LoginComponent />
        </div>
    );
};

export default Login;
