import React from 'react';
import ReactPlayer from "react-player";

const FormVideoPreview = ({url}) => {
    return (
        <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
                <ReactPlayer url={url} controls={true} muted={true}
                             className="h-48 w-full max-w-sm"/>
            </div>
        </div>
    );
};

export default FormVideoPreview;
