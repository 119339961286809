import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import axios from "axios";
import {AiFillFileAdd, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {BsFillArchiveFill} from "react-icons/bs";
import {BiShow} from "react-icons/bi"
import SmallModal from "../components/SmallModal";
import DropDown from "../components/DropDown";
import {getUserToken, userHasExceededMaximumSlides} from "../utils/helpers";
import LoadingGif from "../components/LoadingGif";
import ActionMessage from "../components/ActionMessage";
import CenterMessage from "../components/CenterMessage";
import SlideCard from "../components/SlideCard";
import SEO from "../components/SEO";

const Dias = ({history}) => {

    const [dias, setDias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [showArchiveMessage, setShowArchiveMessage] = useState(false);
    const [showSlideCountExceededMessage, setShowSlideCountExceededMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [diaIdToDelete, setDiaIdToDelete] = useState(undefined);
    const [slideCount, setSlideCount] = useState(-1);

    const handleDeleteSubmit = async (e, id) => {
        e.preventDefault();

        try {
            const deleteResponse = await axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_URL}/dias/${id}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });

            if (deleteResponse.status === 200) {
                window.scrollTo(0, 0);
                setShowDeleteMessage(true);
            }

            await getSlides();
            await getSlidesCount();

        } catch (e) {
            console.log(e);
        }
    }

    const getSlides = async () => {
        try {
            const diasResponse = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/dias`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
            const data = await diasResponse.data;

            if (data) {
                setDias(data);
            }

            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    const getActiveSlides = async () => {
        setLoading(true);

        try {
            const diasResponse = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/dias/active`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });

            if (diasResponse) {
                setDias(diasResponse.data);
            }

            setLoading(false);
        } catch (e) {
            setErrorMessage("No Slides found")
        }
    }

    const getInactiveSlides = async () => {
        setLoading(true);

        try {
            const diasResponse = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/dias/inactive`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });

            if (diasResponse) {
                setDias(diasResponse.data);
            }

            setLoading(false);
        } catch (e) {
            setErrorMessage("No Slides found");
        }
    }

    const handleArchive = async (e, id) => {
        e.preventDefault();
        try {
            const response = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/dias/archive/${id}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
            });

            if (response.status === 200) {
                window.scrollTo(0, 0);
                setShowArchiveMessage(true);
            }

            if (response) {
                getSlides();
            }

        } catch (e) {
            setErrorMessage("Something went wrong");
        }
    }

    const getSlidesCount = async () => {
        const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/dias/count`,
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            },
        });
        setSlideCount(response.data);
    }

    const removeModal = (val) => {
        setShowModal(val);
    }

    useEffect(() => {
        getSlidesCount();
    }, [slideCount])

    useEffect(() => {
        setErrorMessage("");
    }, [dias])

    useEffect(() => {
        let timer = setTimeout(() => {
            setShowDeleteMessage(false);
            setShowArchiveMessage(false);
            setShowSlideCountExceededMessage(false);
        }, 3000)
        return () => {
            clearTimeout(timer);
        }
    }, [showDeleteMessage, showArchiveMessage, showSlideCountExceededMessage])

    useEffect(() => {
        getSlides();
    }, [])

    return (
        <Layout>
            <SEO title="My Slides" description="Create, Read, Update or Delete slides"/>
            {
                showDeleteMessage &&
                <ActionMessage
                    message={"Slide has been removed"}
                    title={"Deleted"}
                    color={"red"}/>
            }
            {
                showArchiveMessage &&
                <ActionMessage
                    message={"Slide has been archived"}
                    title={"Archived"}
                    color={"gray"}/>
            }
            {
                showSlideCountExceededMessage &&
                <ActionMessage
                    message={"Maximum slides created"}
                    title={"Error"}
                    color={"red"}/>
            }

            <DropDown
                getSlides={getSlides}
                getActiveSlides={getActiveSlides}
                getInactiveSlides={getInactiveSlides}/>

            <div className="flex-grow bg-gray-900 relative">
                {
                    loading && errorMessage === "" &&
                    <LoadingGif/>
                }
                {
                    errorMessage !== "" &&
                    <CenterMessage
                        text={"No slides found"}/>
                }
                {
                    !loading && dias &&
                    <div className="mb-4">
                        <p className="font-mono text-xl mt-2 text-white text-center">
                            Slides created: {slideCount}/{process.env.REACT_APP_MAX_SLIDES_PER_USER}
                        </p>
                    </div>
                }
                {
                    !loading && dias &&
                    dias.map((dia) => {
                        return (
                            <div className={`flex flex-col items-center text-white pt-6 my-4 w-2/3 m-auto`}
                                 key={dia.id}>
                                <div className="lg:max-w-5xl justify-center">
                                    <div>
                                        <SlideCard
                                            title={dia.title}
                                            image={dia.image}
                                            description={dia.description}
                                            active={dia.active}/>
                                    </div>
                                    <form>
                                        <div className="text-4xl flex mb-2 border-b">
                                            <button className="text-red-500 hover:scale-110 transform duration-500"
                                                    type="button"
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setDiaIdToDelete(dia.id);
                                                    }}>
                                                <AiFillDelete/>
                                            </button>
                                            <button
                                                className="text-blue-500 mx-6 hover:scale-110 transform duration-500"
                                                onClick={() => {
                                                    history.push(`/dias/edit/${dia.id}`)
                                                }}>
                                                <AiFillEdit/>
                                            </button>
                                            <button
                                                className="text-orange-500 hover:scale-110 transform duration-500 mr-6"
                                                onClick={() => history.push(`/dias/preview/${dia.id}`)}>
                                                <BiShow/>
                                            </button>
                                            <button
                                                className="text-gray-500 text-3xl hover:scale-110 transform duration-500"
                                                onClick={(e) => handleArchive(e, dia.id)}>
                                                <BsFillArchiveFill/>
                                            </button>
                                            {
                                                showModal &&
                                                <SmallModal
                                                    removeModal={() => removeModal()}
                                                    handleDeleteSubmit={e => handleDeleteSubmit(e, diaIdToDelete)}
                                                    diaId={diaIdToDelete}
                                                />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    dias.length < 1 &&
                    <CenterMessage
                        text={"No slides found"}/>
                }

                <div
                    className="right-0 bottom-0 fixed text-green-500 mx-6 mb-12 text-5xl hover:scale-110 transform duration-500">
                    <button onClick={() => {
                        if (userHasExceededMaximumSlides(slideCount)) {
                            window.scrollTo(0, 0);
                            setShowSlideCountExceededMessage(true);
                            return;
                        }
                        history.push("/dias/create");
                    }}>
                        <AiFillFileAdd/>
                    </button>
                </div>
            </div>
        </Layout>
    );
};

export default Dias;
