import React from 'react';
import {Link} from "react-router-dom";
import {RiAdminLine} from "react-icons/ri"
import {AiOutlineMail} from "react-icons/ai"
import {FaRegBuilding} from "react-icons/fa"

const UserCard = ({diaCount, name, company, title, email, username, slides}) => {
    return (
        <div>
            <section className="relative py-8">
                <div className="container mx-auto px-4">
                    <div
                        className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
                        <div className="px-6">
                            <div className="flex flex-wrap justify-center">
                                <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                                    <div className="py-6 px-3 mt-32 sm:mt-0">
                                        <Link onClick={() => {
                                            localStorage.setItem("dias", JSON.stringify(slides));
                                        }}
                                            className="uppercase bg-teal-600 hover:bg-teal-400
                                                    text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded
                                                    outline-none focus:outline-none sm:mr-2 mb-1 transition duration-500"
                                            to={"/dias/presentation/preview/show"}>
                                            Show Presentation
                                        </Link>
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                        <div className="mr-4 p-3 text-center">
                                                    <span
                                                        className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                                                        {diaCount}
                                                    </span>
                                            <span className="text-sm text-gray-500">Slides</span>
                                        </div>
                                        <div className="mr-4 p-3 text-center">
                                                    <span
                                                        className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                                                      1
                                                    </span>
                                            <span className="text-sm text-gray-500">Presentations</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-12 mb-12">
                                <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                                    {name}
                                </h3>
                                <div className="flex justify-around max-w-lg m-auto text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                                    <p className="flex justify-center flex-1 border-r-2 border-teal-500">
                                        <FaRegBuilding size={20} className="text-gray-700"/> <span>&nbsp;</span> {company}
                                    </p>
                                    <p className="flex justify-center flex-1 border-r-2 border-teal-500">
                                        <RiAdminLine size={20} className="text-gray-700"/> <span>&nbsp;</span> {username}
                                    </p>
                                    <p className="flex justify-center flex-1">
                                        <AiOutlineMail size={20} className="text-gray-700"/> <span>&nbsp;</span> {email}
                                    </p>
                                </div>
                                <div className="mb-2 text-gray-700 mt-10">
                                    <span className="flex text-lg font-bold flex-1 rounded-full bg-gray-200 uppercase
                                            justify-center items-center max-w-lg m-auto px-2">
                                        {title}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UserCard;
