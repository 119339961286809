import React from 'react';
import axios from "axios";
import {Draggable} from "react-beautiful-dnd";
import {getImgURL, getUserToken, isVideo} from "../utils/helpers";


const ActiveDia = ({image, title, duration, presentationId, diaId, dias, getPresentation, index, setShowRemoveMessage}) => {

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();

        try {
            const updateResponse = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/presentations/slide/${presentationId}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                data: {
                    id: diaId,
                    dias: dias
                }
            });

            if (updateResponse.status === 200) {
                getPresentation();
                setShowRemoveMessage(true);
                window.scrollTo(0, 0);
            }

        } catch (e) {
            console.log(e);
        }
    }


    return (
        <Draggable draggableId={diaId + ""} key={index} index={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className="flex items-center justify-center my-8">
                        {
                            !isVideo(image.mime) &&
                            <div className="bg-cover bg-center h-auto text-white py-24 px-10 object-fill w-3/4"
                                 style={{backgroundImage: `url(${getImgURL(image.url)})`}}>
                                <div className="md:w-1/2 p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                                    <p className="font-bold text-sm uppercase">{duration} second(s)</p>
                                    <p className="text-3xl font-bold">{title}</p>
                                    <form onSubmit={handleDeleteSubmit}>
                                        <button type="submit"
                                                className="bg-transparent hover:bg-red-400 text-white hover:text-black
                                            font-semibold py-1 px-4 border border-red-500
                                            hover:border-transparent rounded transition duration-500">
                                            REMOVE
                                        </button>
                                    </form>
                                </div>
                            </div>
                        }
                        {
                            isVideo(image.mime) &&
                            <div className="bg-cover bg-center h-auto text-white py-2 px-10 object-fill w-3/4 relative">
                                <video className="bg-gray-300 h-64 w-full rounded-lg shadow-md bg-cover object-fill">
                                    <source src={getImgURL(image.url)}/>
                                </video>
                                <div className="w-full h-full p-4 inner-text-slide absolute"
                                     style={{backgroundColor: 'rgba(0, 0, 0, 0.01)'}}>
                                    <div className="inner-active-slide absolute w-1/2 p-4"
                                         style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                                        <p className="font-bold text-sm uppercase">{duration} second(s)</p>
                                        <p className="text-3xl font-bold">{image.ext}</p>
                                        <form onSubmit={handleDeleteSubmit}>
                                            <button type="submit"
                                                    className="bg-transparent hover:bg-red-400 text-white hover:text-black
                                            font-semibold py-1 px-4 border border-red-500
                                            hover:border-transparent rounded transition duration-500">
                                                REMOVE
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default ActiveDia;
