import React, {useRef} from 'react';
import ReactPlayer from "react-player";
import {getImgURL} from "../utils/helpers";

const Dia = ({title, image, description, logo, playing}) => {

    const player = useRef("");

    return (
        <div className="h-screen bg-black">
            {
                logo &&
                <div className="">
                    <div className="absolute left-0 bottom-0 opacity-75" style={{maxWidth: 125, maxHeight: 125}}>
                        <img src={logo} alt="logo"/>
                    </div>
                </div>
            }
            {
                title &&
                <div className="bg-white text-4xl font-hairline font-semibold text-center py-2 dia-title-top-center
                absolute rounded-lg p-10 mt-6">
                    <h1>{title}</h1>
                </div>
            }
            {
                image && image.mime !== "video/mp4" &&
                <>
                    <div className="bg-cover mx-auto bg-center object-fill w-full h-full"
                         style={{
                             backgroundImage: `url(${getImgURL(image.url)})`
                         }}>
                    </div>
                </>
            }
            {
                image && image.mime === "video/mp4" &&
                <div className="h-full">
                    <ReactPlayer ref={player} url={getImgURL(image.url)} playing={playing}
                                 width="100%" height="100%" muted={true} onPause={() => player.current.seekTo(0)}/>
                </div>
            }
            {
                description &&
                <div className="bg-white dia-text-bottom-center shadow-2xl border-black absolute rounded-lg">
                    <p className="text-xl text-center p-4">
                        {description}
                    </p>
                </div>
            }
        </div>
    );
};

export default Dia;
