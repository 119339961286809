import React from 'react';
import Layout from "../components/Layout";
import dragDropVideo from "../video/drag-drop.mp4";
import createSlide from "../video/create-slide.mp4";
import addRemoveSlide from "../video/add-remove-slides.mp4";
import editRemoveSlide from "../video/edit-remove-slides.mp4";
import archiveSlide from "../video/archive-slide.mp4";
import Question from "../components/Question";
import SEO from "../components/SEO";
import {getMaxFileSizeInMb} from "../utils/helpers";


const Introduction = () => {

    return (
        <Layout>
            <SEO title={"Introduction"} description={"Learn how to use all the features"}/>
            <div className="flex-grow bg-gray-900">

                <Question
                    question={"How to create a slide?"}
                    video={createSlide}
                    description={"Once created, you can add the slide to your presentation"}
                    title={"This is how you create slides"}
                    color={"gray"}/>

                <Question
                    question={"How to edit or delete slides?"}
                    video={editRemoveSlide}
                    description={"A title or description for you slide is optional"}
                    title={"Edit and Delete your slides"}
                    color={"gray"}/>

                <Question
                    question={"How to archive my slides?"}
                    video={archiveSlide}
                    description={"You can restore or delete your archived slides"}
                    title={"Archive your slides"}
                    color={"gray"}/>

                <Question
                    question={"How do I add or remove slides from my presentation?"}
                    video={addRemoveSlide}
                    description={"Simply add and remove slides from your presentation by clicking a button"}
                    title={"This is how to add or remove slides from your presentation"}
                    color={"gray"}/>

                <Question
                    question={"How do I reorder my presentation slides?"}
                    video={dragDropVideo}
                    description={"Do not forget to SAVE your changes!"}
                    title={"This is how to reorder your presentation slides"}
                    color={"gray"}/>

                <Question
                    question={"Can I upload videos?"}
                    title={"Uploading Videos"}
                    color={"gray"}
                    answer={`You can upload videos. Upload any video you want in .MP4 format. 
                    Videos look best recorded with a 16:9 aspect ratio. 
                    Remember, files can not be larger than ${getMaxFileSizeInMb()} MB. `}/>
            </div>
        </Layout>
    );
};

export default Introduction;
