import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import Dia from "../components/Dia";
import {Slide} from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import {isVideo} from "../utils/helpers";
import LoadingGif from "../components/LoadingGif";
import SEO from "../components/SEO";

const Live = () => {
    const slideRef = useRef();
    const [dias, setDias] = useState([]);
    const [videos, setVideos] = useState([]);
    const [playing, setPlaying] = useState(null);
    const [loading, setLoading] = useState(true);

    const slideProperties = {
        duration: 0,
        transitionDuration: 500,
        infinite: true,
        arrows: false,
        autoplay: false,
        pauseOnHover: false,
        onChange: (previous, next) => {
            setDuration(dias[next].duration);

            if (isVideo(dias[previous].image.mime)) {
                setPlaying(false);
            }
            if (isVideo(dias[next].image.mime)) {
                setPlaying(true);
            }
        }
    }

    const getAllPresentation = async () => {
        try {
            if (!loading) return

            const dataResponse = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/presentations`,
            });

            const data = await dataResponse.data;
            setLoading(false);

            const allPresentations = data.map(presentation => { return presentation.dias });
            let presentationDias = [].concat.apply([], allPresentations);

            const presentationVideos = presentationDias.filter(dia => (
                isVideo(dia.image.mime) && dia.image
            ));

            if (presentationDias.length > 0) {
                setDias(dias.concat(...presentationDias))
                setDuration(presentationDias[0].duration)
            }

            if (presentationVideos.length > 0) {
                setVideos(videos.concat(...presentationVideos))
                isVideo(data[0].dias[0].image.mime) ? setPlaying(true) : setPlaying(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const setDuration = (duration) => {
        const durationTimer = setTimeout(() => {
            if (slideRef.current) {
                slideRef.current.goNext()
            }
        }, duration || 5000);
        return () => clearTimeout(durationTimer);
    }

    useEffect(() => {
        getAllPresentation();
    });

    return (

        <div>
            <SEO title="We are Live!" description="Amazing Presentation"/>
            {
                loading &&
                <LoadingGif/>
            }
            {
                !loading &&
                <Slide ref={slideRef} {...slideProperties}>
                    {
                        dias.map((dia) => {
                            return (
                                <div key={dia.id} className="relative">
                                    <Dia
                                        title={dia.title}
                                        image={dia.image}
                                        description={dia.description}
                                        logo={dia.logo ? dia.logo.url : null}
                                        playing={playing}/>
                                </div>
                            )
                        })
                    }
                </Slide>
            }
        </div>
    );
};

export default Live;
